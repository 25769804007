import React, { useState, useContext } from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { piboxAdminRolesCompany } from 'utils';
import { useStateValue } from 'context/store';
import { I18nContext } from 'translations';
import Groups from './Groups';
import Users from './Users';
import MyAccount from './MyAccount';
import FavoriteAddresses from './FavoriteAddresses';
import CompanyFavoriteAddresses from './FavoriteAddresses/CompanyFavoriteAddresses';
import CompanyRegister from './CompanyRegister';
import Whatsapp from './Whatsapp';
import Security from './Security';
import { RENT, COUNTRY_CODE } from 'utils/constants';
const isRent = process.env.REACT_APP_API_URL_BASE_IS_RENT === RENT;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,

    display: 'flex',
    minHeight: 'calc(100vh - 80px)',
    '& .MuiTabs-vertical': {
      minWidth: '160px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'initial',
      '& .MuiTabs-flexContainer': {
        flexDirection: 'row',
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
      },
      '& .Mui-selected': {
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
      },
      '& .MuiTabScrollButton-vertical': {
        display: 'none',
      },
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: {
      borderRight: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  tabRight: {
    width: '100%',
    backgroundColor: '#FAFAFA',
  },
  btnMenu: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      lineHeight: '12px',
    },
  },
}));

export default function Settings() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { translate, langCode } = useContext(I18nContext);
  const [
    {
      checkin: { company, checkin },
    },
  ] = useStateValue();

  const handleChange = (event, newValue) => setValue(newValue);

  const roles = () => {
    let rol = [];
    if (checkin && checkin.profile.roles) {
      rol = piboxAdminRolesCompany(checkin.profile.roles);
    }
    return rol;
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        <Tab
          label={translate('app.settings.myAccount')}
          value={0}
          className={classes.btnMenu}
        />
        <Tab
          label={translate('app.settings.addresses')}
          value={1}
          className={classes.btnMenu}
        />
        {company && COUNTRY_CODE[langCode][1] === 'CO' && (
          <Tab
            label={translate('app.setting.companyRegister.title')}
            value={2}
            className={classes.btnMenu}
          />
        )}
        {company && roles().length > 0 && (
          <Tab
            label={translate('app.settings.companyAddresses')}
            value={3}
            className={classes.btnMenu}
          />
        )}
        {company && roles().length > 0 && (
          <Tab
            label={translate('app.settings.groups')}
            value={4}
            className={classes.btnMenu}
          />
        )}
        {company && roles().length > 0 && (
          <Tab
            label={translate('app.settings.users')}
            value={5}
            className={classes.btnMenu}
          />
        )}
        {company && roles().length > 0 && !isRent && (
          <Tab
            label={translate('Whatsapp')}
            value={6}
            className={classes.btnMenu}
          />
        )}
        {company && roles().length > 0 && !isRent && (
          <Tab
            label="Seguridad"
            value={7}
            className={classes.btnMenu}
          />
        )}
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabRight}>
        <MyAccount />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabRight}>
        <FavoriteAddresses
          company={company}
        />
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.tabRight}>
        <CompanyRegister />
      </TabPanel>
      <TabPanel value={value} index={3} className={classes.tabRight}>
        <CompanyFavoriteAddresses />
      </TabPanel>
      <TabPanel value={value} index={4} className={classes.tabRight}>
        <Groups />
      </TabPanel>
      <TabPanel value={value} index={5} className={classes.tabRight}>
        <Users />
      </TabPanel>
      <TabPanel value={value} index={6} className={classes.tabRight}>
        <Whatsapp />
      </TabPanel>
      <TabPanel value={value} index={7} className={classes.tabRight}>
        <Security />
      </TabPanel>
    </div>
  );
}
