import { SHOW_SECURITY, LOADING_SECURITY } from './constants';
import { flatFormat } from 'utils';

const securityReducer = (state, action) => {
  switch (action.type) {
    case SHOW_SECURITY:
      const data = {
        ...action.security,
        cost: action.security?.cost ? flatFormat({ sub_units: action.security.cost.sub_units, iso: action.security.cost.currency }) : '',
      }
      return {
        ...state,
        security: data
      }
    case LOADING_SECURITY:
      return {
        ...state,
        loadingSecurity: action.loadingSecurity
      }
    default:
      return state
  }
}

export default securityReducer;
