import { string, object, date, bool, ref } from 'yup';

const generateSchema = ({isFromMexico})=>{
  return object().shape({
    name: string().required('requiredField'),
    business_name: string().required('requiredField'),
    fiscal_number: string().required('requiredField'),
    email: string().email('invalidEmailAddress').required('requiredField'),
  phone: string().required('requiredField'),
  website: string().required('requiredField'),
  entity_type: string().required('requiredField'),
  has_accepted_corporate_tc: bool().oneOf([true], 'requiredField'),
  has_authorized_personal_data_treatment : bool().oneOf([true], 'requiredField'),
  has_authorized_credit_report_inquiry : bool().oneOf([true], 'requiredField'),

  bank_id: string().required('requiredField'),
  bank_account_type_id: string().required('requiredField'),
  account_number: string().required('requiredField'),
  account_name: string().required('requiredField'),
  last_name: string().required('requiredField'),
  bank_fiscal_number_type_id: string().required('requiredField'),
  bank_fiscal_number: string().required('requiredField'),
  bank_certification: string().required('requiredField'),

  'credit_request?': bool().oneOf([true, false], 'requiredField'),
  requested_credit_amount: string().when('credit_request?', {
    is: true,
    then: string().required('requiredField'),
  }),
  last_year_financial_statements: string().test({
    name: 'requiredField',
    test: function (value) {
      const credit_request = this.resolve(ref('credit_request?'));
      if (!isFromMexico && credit_request && !value) {
        return this.createError({
          path: 'last_year_financial_statements',
          message: 'requiredField',
        });
      }
      return true;
    },
  }),
  last_income_tax_return: string().test({
    name: 'requiredField',
    test: function (value) {
      const credit_request = this.resolve(ref('credit_request?'));
      if (!isFromMexico && credit_request && !value) {
        return this.createError({
          path: 'last_income_tax_return',
          message: 'requiredField',
        });
      }
      return true;
    },
  }),

  'requires_prefecture?': bool().oneOf([true, false], 'requiredField'),
  prefectural_closing_date: date().when('requieres_prefecture?', {
    is: true,
    then: date().required('requiredField'),
  }),

  tentative_max_declared_value: string().required('requiredField'),
  industry_type_cd: string().required('requiredField'),
  
  rut_document: string().required('requiredField'),
  legal_representative_document: string().required('requiredField'),
  certificate_of_existence_and_legal_representation:
  string().required('requiredField'),
});
}

export default generateSchema;
