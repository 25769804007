import React, { useContext, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { INITIAL_PAGE } from 'utils/constants';
import { useForm } from 'react-hook-form';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { updateAddress } from 'context/sales/actions';
import { modalMessage } from 'utils';
import Loading from 'components/Loading';
import AddressInputs from 'components/CustomInputs/AddressInputs';

const Form = ({ sale, getSales, setOpen }) => {
  const { translate } = useContext(I18nContext);
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useStateValue();
  const { handleSubmit, register, control, errors, clearErrors } = useForm({
    mode: 'onChange'
  });

  const handleMessage = () => {
    setLoading(false);
    modalMessage(translate('congratulations'), translate('app.sales.addressAddedSuccessfully'), 'success', translate('continue')).then(() => {
      setOpen(false);
      getSales({page:INITIAL_PAGE});
    })
  }

  const onSubmit = async values => {
    setLoading(false);
    const callback = await updateAddress(dispatch, sale._id, values);
    callback && callback.status ? handleMessage() : setLoading(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    
      {loading && <Loading />}

      <Typography
        variant="subtitle1"
        className="text-center line-height"
      >
        {translate('app.sales.titleForm')}
      </Typography>
      <br /><br />
      <AddressInputs
        register={register}
        address={address}
        setAddress={setAddress}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
      />
      <br />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
      >
        {translate('app.sales.confirmAddress')}
      </Button>
    </form>
  )
}

export default Form;