import sessionReducer from './users/reducer';
import commonsReducer from './commons/reducer';
import trackingReducer from './tracking/reducer';
import checkinReducer from './checkin/reducer';
import expressReducer from './express/reducer';
import salesReducer from './sales/reducer';
import integrationReducer from './integrations/reducer';
import paymentsReducer from './payments/reducer';
import usersReducer from './settings/users/reducer';
import groupsReducer from './settings/groups/reducer';
import profileReducer from './settings/profile/reducer';
import addressesReducer from './settings/favoriteAddresses/reducer';
import servicesOperationsReducer from './operations/services/reducer';
import optimizeRoutesReducer from './operations/optimizeRoutes/reducer';
import bookingReducer from './booking/reducer';
import unqulifiedBookingsReducer from './rates/reducer';
import companyRegisterReducer from './settings/companyRegister/reducer';
import prepackagesReducer from './prepackages/reducer';
import whatsappReducer from './settings/whatsapp/reducer';
import promoCodesReducer from './promocode/reducer';
import commodateLoanReducer from './commodate/reducer';
import claimsReducer from './claims/reducer';
import balancesReducer from './balances/reducer';
import securityReducer from './settings/security/reducer';

export const indexReducer = (
  {
    session,
    commons,
    tracking,
    checkin,
    bookings,
    sales,
    integrations,
    payments,
    users,
    groups,
    profile,
    servicesOperations,
    optimizeRoutes,
    addresses,
    booking,
    unqulifiedBookings,
    companyRegister,
    prepackages,
    whatsapp,
    promoCodes,
    commodateLoan,
    claims,
    balances,
    security
  },
  action
) => {
  return {
    session: sessionReducer(session, action),
    commons: commonsReducer(commons, action),
    tracking: trackingReducer(tracking, action),
    checkin: checkinReducer(checkin, action),
    bookings: expressReducer(bookings, action),
    sales: salesReducer(sales, action),
    integrations: integrationReducer(integrations, action),
    payments: paymentsReducer(payments, action),
    users: usersReducer(users, action),
    groups: groupsReducer(groups, action),
    profile: profileReducer(profile, action),
    servicesOperations: servicesOperationsReducer(servicesOperations, action),
    optimizeRoutes: optimizeRoutesReducer(optimizeRoutes, action),
    addresses: addressesReducer(addresses, action),
    booking: bookingReducer(booking, action),
    unqulifiedBookings: unqulifiedBookingsReducer(unqulifiedBookings, action),
    companyRegister: companyRegisterReducer(companyRegister, action),
    prepackages: prepackagesReducer(prepackages, action),
    whatsapp: whatsappReducer(whatsapp, action),
    promoCodes: promoCodesReducer(promoCodes, action),
    commodateLoan: commodateLoanReducer(commodateLoan, action),
    claims: claimsReducer(claims, action),
    balances: balancesReducer(balances, action),
    security: securityReducer(security, action)
  };
};
