import React, { useContext } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Container, Typography, Snackbar } from '@material-ui/core';
import { message as closeMessage } from 'context/commons/actions';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { sendPQRS } from 'context/landings/actions';
import LayoutLanding from 'components/Landing/Layouts';
import ContactForm from 'components/Landing/Form/ContactForm';
import Loading from 'components/Loading';

const styles = createStyles(() => ({
  pqrs: {
    marginTop: '68px',
    padding: '60px 10px',
    textAlign: 'justify'
  }
}));

function Pqrs({ classes }) {
  const { translate } = useContext(I18nContext);
  const [{ commons: { loading, message } }, dispatch] = useStateValue(); 

  const onSubmit = async values => await sendPQRS(dispatch, values);

  return (
    <LayoutLanding>
      {loading && <Loading />}

      <div className={classes.pqrs}>
        <Container
          maxWidth="sm"
          className="text-center"
        >
          <Typography
            variant="h5"
            color="textPrimary"
          >
            {translate('pqrs.title')}
          </Typography>
          <Typography
            variant="body2"
            color="textPrimary"
          >
            {translate('pqrs.subtitle')}
          </Typography>
          <br /><br />

          <ContactForm
            submit={onSubmit}
            loading={loading}
            pqr
          />
        </Container>
      </div>
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </LayoutLanding>
  )
}

export default withStyles(styles)(Pqrs);