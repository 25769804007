import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Grid, Snackbar, TextField, Button, makeStyles, FormControlLabel, Checkbox, FormControl } from '@material-ui/core';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { useForm, Controller } from 'react-hook-form';
import { saveSecurity, getSecurity } from 'context/settings/security/actions';
import { modalMessage } from 'utils';
import Loading from 'components/Loading';

const useStyles = makeStyles((theme) => ({
  timeField: {
    margin: theme.spacing(1),
  }
}));

export default function Security() {
  const { translate, langCode } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const [{ commons: { message }, checkin: { company }, security: { security, loadingSecurity } }, dispatch] = useStateValue();
  const classes = useStyles();

  const { handleSubmit, register, reset, control } = useForm();

  
  const [hours, setHours] = useState({
    1: { name: 'Lunes', open: '', close: '' },
    2: { name: 'Martes', open: '', close: '' },
    3: { name: 'Miercoles', open: '', close: '' },
    4: { name: 'Jueves', open: '', close: '' },
    5: { name: 'Viernes', open: '', close: '' },
    6: { name: 'Sabado', open: '', close: '' },
    0: { name: 'Domingo', open: '', close: '' }
  });

  useEffect(() => {
    getSecurity(dispatch, company?._id);
  }, [getSecurity, dispatch, company]); // eslint-disable-line

  useEffect(() => {
    security && reset(security)
  }, [security]); // eslint-disable-line

  useEffect(() => {
    security?.hours && Object.keys(security.hours).map((key) => (
      setHours((prevHours) => ({
        ...prevHours,
        [key]: {
          name: security.hours[key].name,
          open: security.hours[key].open,
          close: security.hours[key].close,
        },
      }))
    ))
  }, [security]); // eslint-disable-line
  
  const handleChange = (day, field, value) => {
    setHours((prevHours) => ({
      ...prevHours,
      [day]: {
        ...prevHours[day],
        [field]: value,
      },
    }));
  };

  const messageOk = () => {
    setLoading(false);
    modalMessage(translate('congratulations'), 'La configuracion ha sido guardada con exito', 'success', translate('accept'), true)
  }

  const submit = async values => {
    setLoading(true);
    const callback = await saveSecurity(dispatch, company?._id, { ...values, hours }, langCode);
    callback.status ? messageOk() : setLoading(false);
  }

  if (loadingSecurity) return <Loading />
  
  return (
    <>
      <Typography variant="h5" color="primary">
        {translate('settings.security')}
      </Typography>
      <br />
      {loading && <Loading />}
      <form onSubmit={handleSubmit(submit)} noValidate>
        <Container maxWidth="md" className="padding0-xs">
          <Grid container alignItems="center" justify="center" spacing={3}>
            <Grid item sm={12} className="text-center">
              <Typography variant='subtitle1' color='primary' className='text-left'>
                {translate('settings.security.hoursOperation')}
              </Typography><br />
              {Object.keys(hours).map((day) => (
                <Grid container alignItems="center" justify="center" spacing={2} key={day}>
                  <Grid item xs={12} sm={2} className='text-right'>
                    <Typography variant='subtitle2'>{hours[day].name}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label={translate('settings.security.hoursOperation.openTime')}
                      type="time"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={hours[day].open}
                      onChange={(e) => handleChange(day, 'open', e.target.value)}
                      className={classes.timeField}
                      fullWidth
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label={translate('settings.security.hoursOperation.closeTime')}
                      type="time"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={hours[day].close}
                      onChange={(e) => handleChange(day, 'close', e.target.value)}
                      className={classes.timeField}
                      fullWidth
                      size='small'
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item sm={12} className="text-left">
              <Typography variant='subtitle1' color='primary' className='text-left'>
                {translate('settings.security.destination')}
              </Typography>
              <Controller
                name='destiny'
                control={control}
                render={({ onChange, value }) => (
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          name='destiny'
                          color="primary"
                        />
                      }
                      label={translate('settings.security.limitDestination')}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item sm={12} className="text-left">
              <Typography variant='subtitle1' color='primary' className='text-left' style={{ marginBottom: 5 }}>
                {translate('settings.security.limitEstimatedCost')}
              </Typography>
              <TextField
                label={translate('settings.security.estimatedCost')}
                defaultValue=""
                variant="outlined"
                size="small"
                inputRef={register}
                name='cost'
                style={{ marginLeft: 20 }}
              />
              <br />
            </Grid>
            <Grid item sm={12} className="text-left">
              <Typography variant='subtitle1' color='primary' className='text-left'>
                {translate('settings.security.limitDistance')}
              </Typography>
              <Controller
                name='limit'
                control={control}
                render={({ onChange, value }) => (
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          name='limit'
                          color="primary"
                        />
                      }
                      label={translate('settings.security.activeLimitDistance')}
                    />
                  </FormControl>
                )}
              />
              <br />
            </Grid>
          </Grid>
        </Container>
        <div className='text-center'>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            className="btn-rounded"
            style={{ marginTop: 20 }}
          >
            {translate('settings.security.save')}
          </Button>
        </div>
        <Snackbar
          style={{ zIndex: 99999 }}
          open={!!message}
          autoHideDuration={6000}
          onClose={() => closeMessage(dispatch, null)}
          message={translate(message)}
        />
      </form>
    </>
  )
}