import React, { useContext, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Button,
  IconButton,
  Hidden,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { startOfDay, endOfDay, formatISO } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import CachedIcon from '@material-ui/icons/Cached';
import { I18nContext } from 'translations';
import {
  INTEGRATIONS_OPTIONS,
  BOOKING_STATUSES
} from 'utils/constants';
import { removeEmptyFilters, integrationStatus, integrationTypeOrders } from 'utils';
import styles from '../../styleTableFilters';
import CityAutoComplete from 'components/CustomInputs/CityAutoComplete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const SalesTableFilters = ({
  classes,
  onFilters,
  list,
  haveData,
  setHaveData,
  order,
  setOrder,
}) => {
  const defaultValues = {
    date_of_purchase_ini: null,
    date_of_purchase_end: null,
    integrator: '',
    order_id: '',
    external_id: '',
    status_cd: '',
    city_id: null,
    fulfillment_status: '',
    order_type: ''
  };
  const { translate } = useContext(I18nContext);
  const { handleSubmit, control, reset, register, setValue, watch, getValues } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const onSubmit = (values) => {
    const params = {
      ...values,
      city_id: values?.city_id?._id,
      date_of_purchase_ini:
        values.date_of_purchase_ini &&
        formatISO(startOfDay(values.date_of_purchase_ini)),
      date_of_purchase_end:
        values.date_of_purchase_end &&
        formatISO(endOfDay(values.date_of_purchase_end)),
    };
    removeEmptyFilters(params);
    onFilters(params);
  };

  const clearFilters = () => {
    reset(defaultValues);
    onFilters('');
  };

  useEffect(() => {
    if (haveData && list?.length === 0) {
      setValue('integration_id', INTEGRATIONS_OPTIONS[1].id);
      setHaveData(false);
    } else {
      setHaveData(false);
    }
  }, [list]); // eslint-disable-line

  useEffect(() => {
    if (getValues('integrator') && getValues('fulfillment_status')) {
      setValue('fulfillment_status', '');
    }
  },[getValues('integrator')]) // eslint-disable-line

  const handleChangeOrder = () => {
    const newOrder = order === 'desc' ? 'asc' : 'desc';
    setOrder(newOrder);
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
      className="relative"
    >
      <Grid container spacing={1}>
        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>
              {translate('app.sales.filter.integrationType')}
            </InputLabel>
            <Controller
              control={control}
              name="integrator"
              label={translate('app.sales.filter.integrationType')}
              variant="outlined"
              as={
                <Select>
                  <MenuItem
                    value={null || undefined}
                    style={{ height: '2.5rem' }}
                  >
                    {' '}
                  </MenuItem>
                  {INTEGRATIONS_OPTIONS.map((intg) => (
                    <MenuItem value={intg.id} key={intg.id}>
                      {intg.name}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="date_of_purchase_ini"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('app.bookings.filter.dateFrom')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  maxDate={new Date()}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <Controller
              name="date_of_purchase_end"
              control={control}
              defaultValue={null}
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  className={classes.filter}
                  size="small"
                  inputVariant="outlined"
                  label={translate('app.bookings.filter.dateTo')}
                  format="dd/MM/yyyy"
                  cancelLabel={translate('cancel')}
                  okLabel={translate('accept')}
                  maxDate={new Date()}
                  invalidDateMessage={translate('invalidFormat')}
                  fullWidth
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            className={classes.filter}
            fullWidth
          >
            <InputLabel>{translate('app.bookings.filter.state')}</InputLabel>
            <Controller
              control={control}
              name="status_cd"
              label={translate('app.bookings.filter.state')}
              variant="outlined"
              defaultValue=""
              as={
                <Select>
                  <MenuItem value="">
                    {translate('app.bookings.filter.choose')}
                  </MenuItem>
                  {Object.keys(BOOKING_STATUSES).map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {translate(BOOKING_STATUSES[key])}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={3} sm={4} xs={6} className={classes.filter}>
          <TextField
            type="text"
            name="order_id"
            variant="outlined"
            label={translate('app.sales.filter.SaleId')}
            fullWidth
            inputRef={register}
            size="small"
          />
        </Grid>
        <Grid item md={3} sm={4} xs={6} className={classes.filter}>
          <Controller
            name="city_id"
            label={translate('app.bookings.filter.state')}
            control={control}
            render={(field) => <CityAutoComplete size='small' {...field} />}
          />
        </Grid>
        {watch('integrator') && (
          <Grid item md={3} sm={4} xs={6} className={classes.filter}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.filter}
              fullWidth
            >
              <InputLabel>
                {translate('app.sales.filter.integrationStatus')}
              </InputLabel>
              <Controller
                control={control}
                name="fulfillment_status"
                label={translate('app.sales.filter.integrationStatus')}
                variant="outlined"
                as={
                  <Select>
                    <MenuItem
                      value={null || undefined}
                      style={{ height: '2.5rem' }}
                    >
                      {' '}
                    </MenuItem>
                    {Object.keys(integrationStatus(getValues('integrator'))).map((key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {translate(integrationStatus(getValues('integrator'))[key])}
                        </MenuItem>
                      );
                  })}
                  </Select>
                }
              />
            </FormControl>
          </Grid>
        )}
        {watch('integrator') && watch('integrator') === 'mercadoflex' && (
          <Grid item md={3} sm={4} xs={6} className={classes.filter}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.filter}
              fullWidth
            >
              <InputLabel>
                {translate('app.sales.filter.integrationTypeOrders')}
              </InputLabel>
              <Controller
                control={control}
                name="order_type"
                label={translate('app.sales.filter.integrationTypeOrders')}
                variant="outlined"
                as={
                  <Select>
                    <MenuItem
                      value={null || undefined}
                      style={{ height: '2.5rem' }}
                    >
                      {' '}
                    </MenuItem>
                    {Object.keys(integrationTypeOrders(getValues('integrator'))).map((key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {translate(integrationTypeOrders(getValues('integrator'))[key])}
                        </MenuItem>
                      );
                  })}
                  </Select>
                }
              />
            </FormControl>
          </Grid>
        )}
        <Grid item md={6} xs={12} className="text-center-sm">
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            style={{ width: 120, marginRight: 10 }}
          >
            {translate('app.bookings.filter.search')}
          </Button>
          <Button
            color="primary"
            size="small"
            type="button"
            style={{ width: 100 }}
            onClick={clearFilters}
          >
            {translate('clear')}
          </Button>
        </Grid>
      </Grid>
      <Grid item  container xs={2} style={{ position: 'absolute', right: 0 }} justify='center'>
        <Grid item xs={6} >
          <Hidden only={['md', 'lg', 'xl']}>
            <IconButton aria-label="order_direction" size='small' onClick={handleChangeOrder}>
              {
                order === 'desc' 
                ? <ArrowDropUpIcon /> 
                : <ArrowDropDownIcon />
              }
            </IconButton>
          </Hidden>
        </Grid>
          <Grid item xs={6} >
          <Button
            color="primary"
            size="small"
            type="submit"
            >
            <CachedIcon fontSize="small" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default withStyles(styles)(SalesTableFilters);
