import React, { useCallback, useEffect, useState, useContext } from 'react';
import AppsIcon from '@material-ui/icons/Apps';
import { Container, Typography } from '@material-ui/core';
import { useStateValue } from 'context/store';
import { listStoresShopify } from 'context/sales/actions';
import { configurationShopify } from 'context/integrations/actions';
import Title from 'components/Titles';
import { modalMessageHtml } from 'utils';
import { I18nContext } from 'translations';
import Loading from 'components/Loading';
import FormConfigurationShopify from 'components/Integrations/Shopify/FormConfigurationShopify';

export default function ConfigurationShopify({ history }) {
  const { translate } = useContext(I18nContext);
  const [loading, setLoading] = useState(false);
  const [{ sales: { listShopifyStores }}, dispatch] = useStateValue();

  const getStores = useCallback(async () => {
    return await listStoresShopify(dispatch)
  }, [dispatch]);

  useEffect(() => {
    getStores();
  }, []) // eslint-disable-line

  const messageOk = () => {
    setLoading(false);
    modalMessageHtml(translate('congratulations'), translate('app.shopify.settingShopify.form.successful'), 'success', translate('app.shopify.settingShopify.goToSales'), true, translate('app.shopify.settingShopify.continueSetting'), false)
      .then((response) => {
        response.isConfirmed ? history.push('/app/bookings/sales') : getStores();
      });
  }

  const submit = async (values) => {
    setLoading(true);
    const callback = await configurationShopify(dispatch, values.store_name, values);
    callback?.status ? messageOk() : setLoading(false);
  }

  if (history.location.pathname === '/app/integrations/shopify/configuration') return window.location.href = '/app/integrations';

  return (
    <div className="padding-page-full-height">
      {loading && <Loading />}
      <Title
        title={translate('app.shopify.settingShopify.title')}
        icon={<AppsIcon className="align-center" style={{ marginRight: 5 }} />}
        subtitle=""
      />

      <br />
      <Typography variant="subtitle1" color="textPrimary">
        {translate('app.shopify.settingShopify.description')}
      </Typography>
      <br />

      <Container maxWidth="xs">
        {listShopifyStores && listShopifyStores.length > 0 ?
          <FormConfigurationShopify
            listShopifyStores={listShopifyStores || []}
            submit={submit}
          />
        : <Typography className="text-center" variant="body1">{translate('thereIsNoData')}</Typography>
        }
      </Container>
    </div>
  );
}