import { post, get } from 'api/requests';
import { message } from '../../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import { COUNTRY_CODE } from 'utils/constants';
import { formatPrice } from 'utils';
import { urlCompany } from 'api';
import { SHOW_SECURITY, LOADING_SECURITY } from './constants';

export const saveSecurity = async(dispatch, id, values, lang) => {
  let status = false;

  const data = {
    ...values,
    cost: {
      sub_units: formatPrice({
        sub_units: values.cost,
        iso: COUNTRY_CODE[lang][4],
      }),
      currency: COUNTRY_CODE[lang][4],
    },
  }

  await post(`${urlCompany}/${id}/configure_security_settings`, { ...data })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
}

export const getSecurity = async(dispatch, id) => {
  dispatch({
    type: LOADING_SECURITY,
    loadingSecurity: true
  });
  await get(`${urlCompany}/${id}/security_settings`)
    .then(({ data }) => {
      dispatch({
        type: SHOW_SECURITY,
        security: data?.data
      });
      dispatch({
        type: LOADING_SECURITY,
        loadingSecurity: false
      });
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      dispatch({
        type: SHOW_SECURITY,
        security: null
      });
      dispatch({
        type: LOADING_SECURITY,
        loadingSecurity: false
      });
      message(dispatch, mssg);
    });
}