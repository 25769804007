import React, {
  useEffect,
  useCallback,
  Suspense,
  useState,
  useContext,
} from 'react';
import {
  Grid,
  Container,
  Paper,
  LinearProgress,
  Snackbar,
  Button,
  Tooltip,
  Link
} from '@material-ui/core';
import { format } from 'date-fns';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { I18nContext } from 'translations';
import { useStateValue } from 'context/store';
import { message as closeMessage } from 'context/commons/actions';
import { listBooking, getTripEvents } from 'context/booking/actions';
import HeaderStatus from 'components/Booking/HeaderStatus';
import TextBooking from 'components/Booking/TextBooking';
import DriverCard from 'components/Cards/DriverCard';
import DriverlessCard from 'components/Cards/DriverlessCard';
import StopsData from 'components/Booking/Stops';
import DetailsPayment from 'components/Booking/Payments/DetailsPayment';
import ScheduledData from 'components/Booking/Scheduled';
import HeaderActions from 'components/Booking/HeaderActions';
import BookingMap from 'components/Booking/BookingMap';
import EstimatedData from 'components/Booking/EstimatedData';
import Loading from 'components/Loading';
import BookingEvents from 'components/Booking/BookingEvents';
import BookingChat from 'components/Booking/BookingChat';
import { lastRelaunched, modalMessage, formatCostTemporal } from 'utils';
import withHelmet from 'utils/withHelmet';
import { IDS_SERVICES_TYPE_PIBOX, IDS_SERVICES_TYPE_RENT, RENT, TYPE_OF_SERVICE } from 'utils/constants';

const isRent = process.env.REACT_APP_API_URL_BASE_IS_RENT === RENT;

function BookingDetail({ match, history }) {
  const {
    params: { id },
  } = match;
  const { translate } = useContext(I18nContext);
  const [
    {
      booking: { list, events },
      commons: { message },
    },
    dispatch,
  ] = useStateValue();
  const [loading, setLoading] = useState(false);
  const [viewEvents, setViewEvents] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [clearTimer, setClearTimer] = useState(3500);
  const [notification, setNotification] = useState(false);


  const query = new URLSearchParams(history.location?.search);
  const companyId = query.get('company_id')

  const getBooking = useCallback(
    async (init) => {
      init && setLoading(true);
      const callback = await listBooking(dispatch, id, { company_id: companyId });
      init && !callback?.loadingBooking && setLoading(false);
    },
    [dispatch, id] // eslint-disable-line
  );

  useEffect(() => {
    getBooking('init');
  }, [getBooking]);

  useEffect(() => {
    const lastId =
      list &&
      list.relaunched_to &&
      lastRelaunched(list) &&
      lastRelaunched(list)._id;
    if (lastId && clearTimer) {
      modalMessage(
        '',
        translate(
          'app.booking.serviceWasReassignedWillBeRedirectedToTheNewDirectory'
        ),
        'info',
        '',
        true,
        true,
        translate('cancel'),
        false,
        clearTimer && clearTimer
      ).then((result) => {
        if (result.dismiss === 'timer') {
          window.location.href = `/bookings/${lastId}`;
        } else if (result.dismiss === 'cancel') {
          setClearTimer('');
        }
      });
    }
    const bookingFetch = [4, 107].indexOf(list?.status_cd) === -1;
    if (list && bookingFetch) {
      let timer = null;
      clearTimeout(timer);
      timer = setTimeout(() => {
        getBooking();
      }, 10000);
    }
  }, [list]); // eslint-disable-line

  const getEvents = async () => {
    setLoadingEvents(true);
    const callback = await getTripEvents(id, dispatch);
    !callback?.loading && setLoadingEvents(false);
  };

  const openTrackService = () => {
    setViewEvents(true);
    getEvents();
  };

  const onClickBack = () => {
    if (viewEvents) {
      setViewEvents(false);
    } else {
      history.push('/app');
    }
  };

  const openViewChat = () => {
    setOpenChat(!openChat);
    setNotification(false);
  };

  return (
    <Container className="booking-detail" style={{ maxWidth: '100%' }}>
      <Paper elevation={0} className="paper-booking">
        <Grid container spacing={5} className="text-center-sm">
          <Grid item md={6} sm={12} xs={12} className="padding-b-sm-5">
            <HeaderStatus
              id={list?._id}
              status={list?.status_cd}
              onClickBack={onClickBack}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12} style={{ paddingTop: 0 }}>
            {list && (
              <HeaderActions
                booking={list}
                history={history}
                openTrackService={openTrackService}
                viewEvents={viewEvents}
                getBooking={getBooking}
              />
            )}
          </Grid>
        </Grid>
        {loading && <Loading />}

        {!viewEvents ? (
          <Grid container spacing={5}>
            <Grid item md={6} sm={12} xs={12} className="padding-b-sm-5">
              <Grid
                container
                justify="center"
                alignItems="center"
                className="booking-data"
              >
                <Grid item sm={12} xs={12}>
                  <TextBooking
                    label={`${translate('app.booking.applicant')}:`}
                    text={list?.passenger?.name ?? ' - '}
                  />

                  <TextBooking
                    label={`${translate('app.booking.company')}:`}
                    text={list?.company?.name ?? ' - '}
                  />

                  <TextBooking
                    label={`${translate('app.booking.date')}:`}
                    text={
                      list?.created_at
                        ? format(
                            new Date(list?.created_at),
                            'dd/MM/yyyy, hh:mm a'
                          )
                        : ' - '
                    }
                  />
                  {IDS_SERVICES_TYPE_PIBOX[list?.requested_service_type_id] && (
                    <TextBooking
                      label={`${translate('app.booking.serviceType')}:`}
                      text={translate(IDS_SERVICES_TYPE_PIBOX[list.requested_service_type_id])}
                    />
                  )}

                  {IDS_SERVICES_TYPE_RENT[list?.requested_service_type_id] && (
                    <TextBooking
                      label={`${translate('app.booking.serviceType')}:`}
                      text={translate(IDS_SERVICES_TYPE_RENT[list.requested_service_type_id])}
                    />
                  )}

                  {list?.get_type_of_pibox_service && (
                    <TextBooking
                      label={`${translate('app.modality')}: `}
                      text={translate(TYPE_OF_SERVICE[list?.get_type_of_pibox_service])}
                    />
                  )}

                  {list?.headquarters && (
                    <TextBooking
                      label={`${translate('app.headquarters')}: `}
                      text={list?.headquarters}
                    />
                  )}
                </Grid>
              </Grid>

              {list?.cost_center && (
                <Grid container alignItems="center" className="booking-data">
                  <Grid item sm={12} xs={12}>
                    <TextBooking
                      label={translate('app.booking.costCenter')}
                      text={list.cost_center.name}
                      type={1}
                    />
                  </Grid>
                </Grid>
              )}
              {list?.pickup_validation_code && (
                <Grid container alignItems="center" className="booking-data">
                  <Grid item sm={12} xs={12}>
                    <TextBooking
                      label={translate('app.booking.pickupCode')}
                      text={list.pickup_validation_code}
                      icon={
                        <Tooltip
                          title={translate('app.booking.pickupCodeText')}
                          placement="right-start"
                        >
                          <InfoOutlinedIcon
                            color="primary"
                            style={{
                              marginLeft: 5,
                              verticalAlign: 'middle',
                              fontSize: '1.5rem',
                            }}
                          />
                        </Tooltip>
                      }
                      type={1}
                    />
                  </Grid>
                </Grid>
              )}

              {list?.scheduled_at && (
                <ScheduledData
                  scheduledAt={list?.scheduled_at}
                  hoursDedicated={list?.hours_dedicated}
                />
              )}

              {list?.booking_reservation_id && (
                <Grid container alignItems="center" className="booking-data">
                  <Grid item sm={12} xs={12}>
                    <TextBooking
                      label={translate('bookingId')}
                      text={<Link href={list.booking_reservation_id} target="_blank">{list.booking_reservation_id}</Link>}
                      type={1}
                    />
                  </Grid>
                </Grid>
              )}

              {list?.total_weight > 0 && (
                <Grid container alignItems="center" className="booking-data">
                  <Grid item sm={12} xs={12}>
                    <TextBooking
                      label={`${translate('app.booking.totalWeight')}: `}
                      text={`${list.total_weight} ${translate('grams')}`}
                    />
                  </Grid>
                </Grid>
              )}

              <DetailsPayment
                paymentMethod={list?.payment_method_cd}
                wallet={list?.use_wallet_balance}
                isRent={isRent}
                promoCode={list?.promo_code_info?.code}
              />

              {list?.driver ? (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className="booking-data"
                >
                  <DriverCard
                    isBooking
                    driver={list?.driver}
                    vehicle={list?.served_vehicle}
                  />
                </Grid>
              ) : list?.status_cd === 110 && (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className="booking-data"
                >
                  <DriverlessCard
                    img='/images/booking/freight.svg'
                    text={translate('app.booking.driverlessText')}
                  />
                </Grid>
              )}

              <Grid container alignItems="center" className="booking-data">
                <StopsData
                  key="origen-stop"
                  location={list?.address}
                  icon={
                    <img
                      src="/images/tracking/home.png"
                      alt="origin-pibox"
                      style={{ height: '27px', marginRight: 15 }}
                    />
                  }
                  letter="A"
                  finished={[4, 6, 7].indexOf(list?.status_cd) !== -1}
                  packages={null}
                  type={0}
                  payToOrigin={list?.money_to_pay_for_package ? formatCostTemporal(list.money_to_pay_for_package) : null}
                />
                {list?.stops?.map((stop, index) => (
                  <StopsData
                    key={`stops-${index.toString()}`}
                    location={stop.address}
                    icon={
                      <img
                        src="/images/tracking/destination_marker.svg"
                        alt="marker-pibox"
                        style={{ width: '17px', marginRight: 10 }}
                      />
                    }
                    letter={stop.is_return_stop ? 'A' : (index + 1).toString()}
                    finished={stop.finished}
                    customer={stop.customer && stop.customer}
                    packages={stop.packages || []}
                    type={1}
                    bookingStatus={list.status_cd}
                  />
                ))}

                {isRent && list?.stops?.length === 0 &&
                  <StopsData
                    key="destination-stop"
                    location={list?.end_address}
                    icon={
                      <img
                        src="/images/tracking/destination_marker.svg"
                        alt="destination-pibox"
                        style={{ height: '27px', marginRight: 15 }}
                      />
                    }
                    letter="1"
                    finished={[4, 6, 7].indexOf(list?.status_cd) !== -1}
                    packages={null}
                    type={0}
                  />
                }
              </Grid>
            </Grid>
            <Grid item md={6} sm={12} xs={12} style={{ paddingTop: 0 }}>
              {list && (
                <Suspense fallback={<LinearProgress color="primary" />}>
                  <BookingMap booking={list} />
                </Suspense>
              )}

              <EstimatedData
                companyEstimatedCost={list?.company_estimated_cost}
                companyFinalCost={list?.company_final_cost}
                finalCost={list?.final_cost}
                estimatedCost={list?.estimated_cost}
                estimatedTraveledDistance={list?.estimated_traveled_distance}
                estimatedTraveledTime={list?.estimated_traveled_time}
              />
            </Grid>
          </Grid>
        ) : (
          events && (
            <BookingEvents
              events={events}
              loading={loadingEvents}
              createdAt={list?.created_at}
            />
          )
        )}
        <Button
          variant="text"
          color="default"
          type="button"
          className="btn-chat"
          onClick={openViewChat}
        >
          {notification && !openChat && <figure className="notification" />}
          <img src="/images/booking/chat-bubble.svg" alt="chat" />
        </Button>
        {openChat && list && (
          <BookingChat
            bookingId={list._id}
            driver={list.driver && list.driver}
            openViewChat={openViewChat}
            disabledChat={
              [1, 2, 3, 5, 6, 7, 9, 10, 11, 106].indexOf(list.status_cd) !== -1
            }
          />
        )}
      </Paper>
      <Snackbar
        style={{ zIndex: 99999 }}
        open={!!message}
        autoHideDuration={6000}
        onClose={() => closeMessage(dispatch, null)}
        message={translate(message)}
      />
    </Container>
  );
}

export default (
  withHelmet({
    title: isRent ? 'Picap Rent' : 'Envíos y Mensajería Urbana | Pibox',
    description: isRent ? 'Picap Rent' : 'Realiza envíos de paquetes y carga a todo el país',
    image: isRent ? '/faviconPicap.png' : 'https://pibox.app/images/landing/cargo.png',
    noIndex: true,
    favicon: isRent ? '/faviconPicap.png' : '/favicon.ico'
  })(BookingDetail)
);