import { get, patch, post } from 'api/requests';
import { urlBooking, urlBookings } from 'api';
import { message } from '../commons/actions';
import handleErrorMessage from 'utils/handleErrorMessage';
import {
  LIST_BOOKING,
  LIST_EVENTS,
  GET_AMOUNT_LEFT_TO_COLLECT_BY_COMPANY,
} from './constants';

export const listBooking = async (dispatch, id, company_id = {}) => {
  let loadingBooking = true;
  await get(`${urlBooking}/${id}`, company_id)
    .then(({ data }) => {
      dispatch({
        type: LIST_BOOKING,
        list: data,
      });
      loadingBooking = false;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loadingBooking = false;
    });
  return { loadingBooking };
};

export const cancelBookingAction = async (id, token, dispatch) => {
  let loadingCancel = true;
  await patch(`${urlBooking}/${id}/cancel`, { t: token })
    .then(() => {
      window.location.reload();
      loadingCancel = false;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loadingCancel = false;
    });
  return { loadingCancel };
};

export const relaunchedBookingAction = async (id, dispatch, token) => {
  let loadingRelaunched = true;
  await post(`${urlBooking}/${id}/relaunch`, { t: token })
    .then((json) => {
      return json.data;
    })
    .then((currentBooking) => {
      loadingRelaunched = false;
      if (currentBooking.relaunched_to && currentBooking.relaunched_to._id) {
        window.location.href = `/bookings/${currentBooking.relaunched_to._id}`;
      } else {
        alert('Oops, un servicio no puede ser relanzado más de 3 veces.');
      }
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loadingRelaunched = false;
    });
  return { loadingRelaunched };
};

export const getTripEvents = async (id, dispatch) => {
  let loading = true;
  await get(`${urlBooking}/${id}/events`, {})
    .then(({ data }) => {
      dispatch({
        type: LIST_EVENTS,
        events: data,
      });
      loading = false;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loading = false;
    });
  return { loading };
};

export const confirmCollection = async (dispatch, id, amount) => {
  let status = false;
  await post(`${urlBookings}/${id}/collect_counter_delivery`, amount)
    .then((json) => {
      return json.data;
    })
    .then(() => {
      status = true;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      status = false;
    });
  return { status };
};

export const getPendingCollection = async (dispatch, id) => {
  let loading = true;
  await get(`${urlBookings}/${id}/amount_left_to_collect_by_company`)
    .then(({ data }) => {
      dispatch({
        type: GET_AMOUNT_LEFT_TO_COLLECT_BY_COMPANY,
        amountLeftToCollectByCompany: data,
      });
      loading = false;
    })
    .catch((error) => {
      const mssg = handleErrorMessage(error, dispatch);
      message(dispatch, mssg);
      loading = false;
    });
  return { loading };
};
